import React, { useContext, useState, useEffect } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { Policy } from "@/new_components"

const Index = () => {
  return (
    <Layout menuBlack={true}>
      <SEO page="Home" />
      <Policy policyType='tos' />
    </Layout>
  )
}

export default Index
